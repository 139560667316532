<template>
  <div>
  <v-layout wrap>
    <v-flex xs12 class="title">
      <h2 
      >
        Brightsign [{{network}}]
      </h2>
    </v-flex>
  </v-layout>
  <v-layout wrap
    v-if="presentations"
  >
    <v-flex 
      xs4 
      class="item" 
      style="text-align: left;"        
      :style="{ color: prepareColor(presentations) }"
    >
      Presentations
    </v-flex>
    <v-flex xs2 class="item" 
      :style="{ color: prepareColor(presentations) }"
    >
    </v-flex>
    <v-flex xs6 class="item" 
      :style="{ color: prepareColor(presentations) }"
    >
      {{presentations.toISOString().replace('T', ' ').split('.').shift()}} 
    </v-flex>
  </v-layout>
  <v-layout wrap
    v-if="devices"
  >
    <v-flex 
      xs4 
      class="item" 
      style="text-align: left;"        
      :style="{ color: prepareColor(devices) }"
    >
      Devices
    </v-flex>
    <v-flex xs2 class="item" 
      :style="{ color: prepareColor(devices) }"
    >
    </v-flex>
    <v-flex xs6 class="item" 
      :style="{ color: prepareColor(devices) }"
    >
      {{devices.toISOString().replace('T', ' ').split('.').shift()}} 
    </v-flex>
  </v-layout>
  <v-layout wrap
    v-if="contents"
  >
    <v-flex 
      xs4 
      class="item" 
      style="text-align: left;"        
      :style="{ color: prepareColor(contents) }"
    >
      Contents
    </v-flex>
    <v-flex xs2 class="item" 
      :style="{ color: prepareColor(contents) }"
    >
    </v-flex>
    <v-flex xs6 class="item" 
      :style="{ color: prepareColor(contents) }"
    >
      {{contents.toISOString().replace('T', ' ').split('.').shift()}} 
    </v-flex>
  </v-layout>
  <v-layout wrap>
    <v-flex xs12 class="action">
      <a @click="handleGetCronResult">refresh</a>
    </v-flex>
  </v-layout>
  </div>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
export default {
  data: () => ({
    presentations: null,
    contents: null,
    devices: null,
  }),
  computed: {
    network () {
      return 'DESCUBREGROUP'
    },
  },
  mounted () {
    this.handleGetCronResult()
  },
  methods: {
    prepareColor  () {
      return 'black'
      /*
      let second = 0
      let aux = v.Dif.split(':').map(item => item | 0)
      second += aux.pop()
      second += aux.pop() * 60
      second += aux.pop() * 60 * 60
      
      if (v.ID === 'hotels' && second > 86400) return 'red'
      if (v.ID === 'categories' && second > 86400) return 'red'
      if (v.ID === 'pias' && second > 1800) return 'red'
      return 'black'
        */
    },
    handleGetCronResult () {
      apiBrightsign.put(`/v1/private/cron/last-sync-date`, { type: 'contents', networkID: this.network })
        .then(response => {
          this.contents = response.data && response.data.LMU ? new Date(response.data.LMU) : null
        })
        .catch(error => {
          console.log(error)
        })
      apiBrightsign.put(`/v1/private/cron/last-sync-date`, { type: 'presentations', networkID: this.network })
        .then(response => {
          this.presentations = response.data && response.data.LMU ? new Date(response.data.LMU) : null
        })
        .catch(error => {
          console.log(error)
        })
      apiBrightsign.put(`/v1/private/cron/last-sync-date`, { type: 'devices', networkID: this.network })
        .then(response => {
          this.devices = response.data && response.data.LMU ? new Date(response.data.LMU) : null
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
<style scoped>
.title {
  background-color: #ccc;
  text-align: center;
  padding: 20px 10px;
}
.item {
  text-align: center;
  padding: 20px 10px;
  border-bottom: 1px solid #CCCCCC80;
}
.action {
  padding: 10px;
  text-align: right;
}
</style>

